import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';
import { StateModel } from '@app/user-settings/models/state-model';
import { Observable } from 'rxjs';
import { CountyModel } from '@app/user-settings/models/county-model';
import { LuPhoneTypeModel } from '@app/user-settings/models/lu-phone-type-model';
import { CheckModel } from '@shared/authentication/model/check-model';
import { leadCreateModel } from '../model/lead-add-model';
import { agentFilerModel } from '@app/calendar/model/agent-model';
import { TagViewModel } from '@app/user-settings/models/user-tags-model';
import { leadFilterModel } from '../model/lead-filter-model';
import { leadMedicationModel } from '../model/lead-medication-model';
import { LeadStatusEditModel } from '../model/lead-status-edit-model';
import { CallQueueViewModel } from '@app/user-settings/models/call-queues-model';
import { BaseLookupModel } from '@app/user-settings/models/agent-lookup-model';
import { LeadStatusGroupViewModel } from '@app/lead/model/lead-status-group-view.model';
import { LeadData, LeadViewModel } from '@app/lead/model/lead-view.model';
import { UserTagViewModel } from '@app/lead/model/user-tag.model';
import { UserAgentViewModel } from '@app/user-settings/models/user-agent.model';
import { UserCallQueueViewModel } from '@app/lead/model/user-call-queue-view.model';
import { Cacheable } from 'ts-cacheable';
@Injectable({ providedIn: 'root' })
export class LeadListService {
    constructor(private httpUtility: HttpUtility) {}
    @Cacheable()
    getCounties(id: string): Observable<CountyModel[]> {
        return this.httpUtility.get<CountyModel[]>(`${environment.apiUrl}/counties?stateId=${id}`);
    }
    getAllLeads(getLeadList): Observable<LeadData> {
        return this.httpUtility.post(`${environment.apiUrl}/leads`, getLeadList);
    }

    addLead(addLead: leadCreateModel) {
        return this.httpUtility.post(`${environment.apiUrl}/leads/add`, addLead);
    }

    deleteLead(deleteLead: string[]) {
        return this.httpUtility.delete(`${environment.apiUrl}/leads`, deleteLead);
    }

    getAllUserNotes(Id: number) {
        return this.httpUtility.get(`${environment.apiUrl}/user/${Id}/user-notes`);
    }

    getLeadSource(): Observable<BaseLookupModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/lu-lead-sources`);
    }

    getAgentList(): Observable<BaseLookupModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/users/agent-lookup`);
    }

    leadStatusGroup(): Observable<LeadStatusGroupViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/lead-status-groups`);
    }

    getStates(): Observable<StateModel[]> {
        return this.httpUtility.get<StateModel[]>(`${environment.apiUrl}/states`);
    }

    getPhoneTypes() {
        return this.httpUtility.get<LuPhoneTypeModel[]>(`${environment.apiUrl}/lu-phone-types`);
    }

    getUserByEmail(email: string): Observable<CheckModel> {
        return this.httpUtility.get<CheckModel>(`${environment.apiUrl}/users/verify-email?email=${email}`);
    }

    getAllAgents() {
        return this.httpUtility.get<agentFilerModel[]>(`${environment.apiUrl}/users/agent-lookup`);
    }

    getTags(): Observable<TagViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/tags`);
    }

    getFilterLeads(leadFilter: leadFilterModel): Observable<LeadData> {
        return this.httpUtility.post(`${environment.apiUrl}/leads`, leadFilter);
    }

    getLeadStatusHistory(id: string): Observable<UserAgentViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/user/${id}/user-agents`);
    }

    getLeadMedication(id: string) {
        return this.httpUtility.get<any[]>(`${environment.apiUrl}/user/${id}/user-medications`);
    }

    editLeadMedication(id: string, payload) {
        return this.httpUtility.put<any[]>(`${environment.apiUrl}/user/${id}/user-medications`, payload);
    }

    deleteLeadMedication(userId: string, id: string) {
        return this.httpUtility.delete<any[]>(`${environment.apiUrl}/user/${userId}/user-medications/${id}`);
    }

    addLeadMedication(id: string, leadMedication: leadMedicationModel) {
        return this.httpUtility.post(`${environment.apiUrl}/user/${id}/user-medications`, leadMedication);
    }

    getLeadDetailsById(id: string): Observable<LeadViewModel> {
        return this.httpUtility.get(`${environment.apiUrl}/leads/${id}`);
    }

    convertToClient(id: string) {
        return this.httpUtility.put(`${environment.apiUrl}/leads/${id}/convert-to-client`, {});
    }

    updateLeadStatusDetails(leadStatusEditModel: LeadStatusEditModel) {
        return this.httpUtility.post(`${environment.apiUrl}/user/${leadStatusEditModel.userId}/user-agents`, leadStatusEditModel);
    }

    updateLead(updateModel, id: string) {
        return this.httpUtility.put(`${environment.apiUrl}/leads/${id}`, { ...updateModel });
    }

    getLeadTags(): Observable<TagViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/tags`);
    }

    getUserLeadTags(id: string): Observable<UserTagViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/users/${id}/user-tags`);
    }

    addLeadTag(userId: string, tagId: string) {
        return this.httpUtility.post(`${environment.apiUrl}/users/${userId}/user-tags?tagId=${tagId}`, '');
    }

    deleteLeadTag(userId: string, tagId: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/users/${userId}/user-tags/${tagId}`);
    }

    getCallQueues(): Observable<CallQueueViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/callqueue`);
    }

    addCallQueue(userId: string, calId: string) {
        return this.httpUtility.post(`${environment.apiUrl}/users/${userId}/user-call-queues?callQueueId=${calId}`, '');
    }

    getUserCallQueue(id: string): Observable<UserCallQueueViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/users/${id}/user-call-queues`);
    }

    deleteLeadCall(userId: string, calId: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/users/${userId}/user-call-queues/${calId}`);
    }

    checkLeadExist(payload) {
        return this.httpUtility.post(`${environment.apiUrl}/leads/duplicates`, payload);
    }

    restoreLead(id: string) {
        return this.httpUtility.post(`${environment.apiUrl}/leads/${id}/restore`, {});
    }

    sendSunfireLead(payload) {
        return this.httpUtility.post<any>(`${environment.sunfireAPIURL}`, payload);
    }
}
