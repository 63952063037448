/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { VoiceGreetingViewModel } from '@app/shared/models/call-log-model';
import { BaseLookupModel } from '@app/user-settings/models/call-queues-model';
import { AgentLookupService } from '@app/user-settings/services/agent-lookup-service';
import { VoiceGreetingService } from '@app/user-settings/services/voice-greeings.services';
import { BaseComponentDirective } from '@core/component/base.component';
import { ConfirmationService } from 'primeng/api';

import { takeUntil } from 'rxjs';

@Component({
    selector: 'app-voice-greetings',
    templateUrl: './voice-greetings.component.html',
    styleUrls: ['./voice-greetings.component.scss'],
})
export class VoiceGreetingsComponent extends BaseComponentDirective implements OnInit {
    @ViewChild('f', { static: true }) ngForm: NgForm;
    loading = false;
    greetingsList: VoiceGreetingViewModel[] = [];
    display = false;
    showModal = false;
    selectedRow: FileUploadModel;
    ModalTitle = '';
    ButtonText = 'Submit';
    agents: BaseLookupModel[];
    selectedAgents: string[] = [];
    fileName = '';
    selectedFile: File;
    selectedUsers: string[] = [];
    // allowValue: boolean;
    showLoader = false;
    showAudioUpload: boolean;
    isAdmin: boolean;
    loggedInUser: string;

    constructor(
        private voiceGreetingService: VoiceGreetingService,
        private agentLookupService: AgentLookupService,
        private confirmationService: ConfirmationService,
        private authenticationService: AuthenticationService,
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.selectedRow = new FileUploadModel();
        this.getAllVoiceGreetings();
        this.getAgetLookupService();
        this.isAdmin = this.authenticationService.isAdmin();
        this.loggedInUser = this.authenticationService.getLoggedInId();
    }

    getAllVoiceGreetings() {
        this.loading = true;
        this.voiceGreetingService
            .getAudioGreetings()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (res) => {
                    this.loading = false;
                    this.greetingsList = res;
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'something went wrong, please try again',
                    });
                },
            });
    }

    cancel() {
        this.showModal = false;
        this.selectedRow = new FileUploadModel();
        this.selectedAgents = [];
    }

    createGreetVoiceModal() {
        this.display = true;
        this.showModal = true;
        this.ModalTitle = 'Add';
        this.ButtonText = 'Submit';
        this.selectedRow = new FileUploadModel();
        this.showAudioUpload = true;
    }

    editVoiceGreeting(voiceGreetingViewModel: VoiceGreetingViewModel) {
        this.display = true;
        this.showModal = true;
        this.ModalTitle = 'Edit';
        this.ButtonText = 'Update';
        this.selectedRow = voiceGreetingViewModel;
        this.showAudioUpload = false;
        this.selectedRow.users.forEach((element) => {
            this.selectedAgents.push(element.id);
        });
    }

    getAgetLookupService(): void {
        this.agentLookupService.getAgentLookupDetails().subscribe((result) => {
            this.agents = result;
        });
    }

    fileUploadChange(event: Event) {
        const files = (event.target as HTMLInputElement).files;
        if (files && files.length) {
            this.selectedFile = files[0];
            this.fileName = files[0].name;
        }
        console.log(files);
    }

    createAndUpdateGreetings() {
        const form = new FormData();
        form.append('VoiceFile', this.selectedFile);
        form.append('name', this.selectedRow?.name);
        form.append('statusId', '1');
        form.append('allowAllUsers', this.isAdmin === true ? new Boolean(this.selectedRow.allowAllUsers).toString() : new Boolean(false).toString());
        form.append('description', this.selectedRow?.name);
        form.append('descriptor', this.selectedRow?.name);
        if (this.isAdmin) {
            if (this.selectedUsers && this.selectedUsers.length > 0) {
                let index = 0;
                this.selectedUsers.forEach((a) => {
                    form.append(`users[${index}]`, a.toString());
                    index = index + 1;
                });
            }
        } else {
            form.append(`users[0]`, this.loggedInUser.toString());
        }

        form.append('displayOrder', this.selectedRow?.displayOrder.toString());
        this.showLoader = true;
        if (this.ButtonText === 'Submit') {
            this.voiceGreetingService.createAudioGreetings(form).subscribe({
                next: () => {
                    this.showLoader = false;
                    this.cancel();
                    this.selectedRow = new FileUploadModel();
                    this.selectedAgents = [];
                    this.getAllVoiceGreetings();
                    this.selectedUsers = [];
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: `Voice Greeting deleted Successfully`,
                    });
                },
                error: () => {
                    this.showLoader = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'something went wrong, please try again',
                    });
                },
            });
        } else {
            let usersList;
            if (this.isAdmin) {
                usersList = this.selectedRow.allowAllUsers === true ? (this.selectedRow.users = []) : this.selectedAgents;
            } else {
                usersList = this.loggedInUser;
            }

            const editVoiceGreeing: FileUploadModel = {
                allowAllUsers: this.selectedRow.allowAllUsers,
                description: this.selectedRow.description,
                descriptor: this.selectedRow.description,
                displayOrder: this.selectedRow.displayOrder,
                filePath: this.selectedRow.filePath,
                id: this.selectedRow.id,
                name: this.selectedRow.name,
                statusId: 1,
                users: usersList,
            };

            this.voiceGreetingService.updateAudioGreetings(editVoiceGreeing).subscribe({
                next: () => {
                    this.showLoader = false;
                    this.cancel();
                    this.selectedRow = new FileUploadModel();
                    this.selectedAgents = [];
                    this.getAllVoiceGreetings();
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: `Voice Greeting edited Successfully`,
                    });
                },
                error: () => {
                    this.showLoader = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'something went wrong, please try again',
                    });
                },
            });
        }
    }

    getSelectedAgents(e) {
        this.selectedUsers.push(e.itemValue);
    }

    deleteVoiceGreeting(voiceGreetingViewModel: VoiceGreetingViewModel) {
        this.voiceGreetingService.delteAudioGreetings(voiceGreetingViewModel.id).subscribe({
            next: () => {
                this.getAllVoiceGreetings();
                this.selectedRow = new FileUploadModel();
                this.selectedAgents = [];
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: `${this.selectedRow.name} deleted Successfully`,
                });
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong, please try again',
                });
            },
        });
    }

    confirmDelete(voiceGreetingViewModel: VoiceGreetingViewModel) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteVoiceGreeting(voiceGreetingViewModel);
            },
        });
    }
}
export class FileUploadModel {
    name: string;
    displayOrder: number;
    allowAllUsers: boolean;
    filePath: string;
    users: any;
    description: string;
    descriptor: string;
    id?: string;
    statusId: number;
}
