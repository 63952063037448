import { Injectable } from '@angular/core';
import { MegaMenuItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { Router } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class MenuService {
    private menuSubject = new BehaviorSubject<MegaMenuItem[]>([{}]);
    currentMenu = this.menuSubject.asObservable();
    constructor(private router: Router, private readonly authenticationService: AuthenticationService) { }
    public get MenuObservable(): Observable<MegaMenuItem[]> {
        return new BehaviorSubject<MegaMenuItem[]>(this.menus).asObservable();
    }

    get menus(): MegaMenuItem[] {
        const Menu: MegaMenuItem[] = [
            {
                label: 'Dashboard',
                icon: 'pi pi-fw pi-home',
                command: () => {
                    this.dashboard();
                    this.clearBodyClass();
                },
            },
            {
                label: 'Leads',
                icon: 'pi pi-fw pi-user-plus',
                command: () => {
                    this.leadList();
                    this.clearBodyClass();
                },
            },
            {
                label: 'Clients',
                icon: 'pi pi-fw pi-users',
                command: () => {
                    this.clientList();
                    this.clearBodyClass();
                },
            },
            {
                label: 'Calendar',
                icon: 'pi pi-fw pi-calendar',
                command: () => {
                    void this.router.navigate(['calendar']);
                    this.clearBodyClass();
                },
            },
            {
                label: 'Reports',
                icon: 'pi pi-fw pi-file',
                items: [
                    [
                        {
                            items: [
                                {
                                    label: 'Call Logs',
                                    command: () => {
                                        void this.router.navigate(['/reports/call-logs']);
                                        this.clearBodyClass();
                                    },
                                    icon: 'pi pi-fw pi-user-edit',
                                },
                            ],
                        },
                        {
                            items: [
                                {
                                    label: 'Call Logs Summary',
                                    command: () => {
                                        void this.router.navigate(['/reports/call-logs-summary']);
                                        this.clearBodyClass();
                                    },
                                    icon: 'pi pi-fw pi-user-edit',
                                },
                            ],
                        },
                    ],
                ],
            },
        ];

        if (this.authenticationService.isAdmin()) {
            const adminMenu = {
                label: 'Admin',
                icon: 'pi pi-fw pi-user',

                items: [
                    [
                        {
                            items: [
                                {
                                    label: 'Users',
                                    command: () => {
                                        void this.router.navigate(['/users/manage-users']);
  //                                      this.clearBodyClass();
                                    },
                                    icon: 'pi pi-fw pi-user-edit',
                                },
                                {
                                    label: 'Calendar Categories',
                                    command: () => {
                                        this.calendarCategory();
                                        this.clearBodyClass();
                                    },
                                    icon: 'pi pi-fw pi-calendar',
                                },
                                {
                                    label: 'Lead Status',
                                    command: () => {
                                        void this.router.navigate(['lead-status']);
                                        this.clearBodyClass();
                                    },
                                    icon: 'pi pi-fw pi-list',
                                },
                                {
                                    label: 'Tags',
                                    command: () => {
                                        void this.router.navigate(['/user-settings/user-tags']);
                                        this.clearBodyClass();
                                    },
                                    icon: 'pi pi-fw pi-tags',
                                },
                                {
                                    label: 'Call Queues',
                                    command: () => {
                                        void this.router.navigate(['/user-settings/call-queues']);
                                        this.clearBodyClass();
                                    },
                                    icon: 'pi pi-fw  pi-phone',
                                },
                                {
                                    label: 'Voice Greetings',
                                    command: () => {
                                        void this.router.navigate(['/user-settings/voice-greetings']);
                                        this.clearBodyClass();
                                    },
                                    icon: 'pi pi-fw  pi-volume-down',
                                },
                                {
                                    label: 'Manage Lead Sources',
                                    command: () => {
                                        void this.router.navigate(['/user-settings/manage-lead-sources']);
                                        this.clearBodyClass();
                                    },
                                    icon: 'pi pi-fw  pi-users',
                                },
                            ],
                        },
                    ],
                ],
            };

            Menu.push(adminMenu);
        }

        return Menu;
    }
    updateMenus(): void {
        this.menuSubject.next(this.menus);
    }

    leadList(): void {
        void this.router.navigate(['leads/lead-list']);
    }

fileBrowser(): void {
    void this.router.navigate(['file-browser/file-manager']);
}

    clientList(): void {
        void this.router.navigate(['clients/client-list']);
    }

    comingSoon(): void {
        void this.router.navigate(['coming-soon']);
    }

    dashboard(): void {
        void this.router.navigate(['dashboard']);
    }

    calendarCategory(): void {
        void this.router.navigate(['calendar/calendar-categories']);
    }

    clearBodyClass(): void {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('dialog-open');
    }
}
